<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div class="mt-4" style="border-left: 1px solid #878E99; height:1.5rem" />

      <div style="width: 220px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          New Advance
        </h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div class="px-3">
      <card class="p-5 mt-6 ">
        <div class="flex mb-14">
          <span class="w-9/12">
            <p class="text-lg font-bold">
              Advance Request Form
            </p>
          </span>
          <span class="text-xl text-romanSilver float-right">
            My Annual Gross Salary :<span class=" text-optima font-bold"
              >{{ convertToCurrency(grossPay) }}
            </span>
          </span>
        </div>
        <div class="grid grid-cols-12 gap-7" style="margin-top:1.125rem">
          <div class="col-span-4">
            <div class="text-sm text-jet">
              Name Of Advance
            </div>
            <c-select
              placeholder="--Select--"
              :options="payItemList"
              variant="w-full"
              @onChange="handleSelected($event)"
            />
          </div>
          <div class="col-span-4">
            <div class="text-sm text-jet">
              Advance Amount
            </div>
            <c-text
              placeholder="Enter"
              variant="w-full"
              :errors="error"
              :disabled="!salaryAdvanceRequest.paySettingId"
              v-model="loanAmount"
            />
          </div>
          <div class="col-span-4">
            <div class="text-sm text-jet">
              Interest Rate
            </div>
            <c-text
              placeholder="Enter"
              variant="w-full"
              v-model="salaryAdvanceRequest.interestRate"
              disabled
            />
          </div>
          <div class="col-span-4">
            <div class="text-sm text-jet">
              Repayment Period (Months)
            </div>
            <c-select
              placeholder="--Select--"
              :options="repaymentPeriodArray"
              variant="w-full"
              v-model="salaryAdvanceRequest.repaymentDuration"
              @onChange="calculatePrincipalAmount"
              :disabled="!loanAmount"
            />
          </div>
          <div class="col-span-4">
            <div class="text-sm text-jet">
              Effective Repayment Amount
            </div>
            <c-text
              placeholder="Enter"
              variant="w-full"
              v-model="salaryAdvanceRequest.repaymentAmount"
              disabled
            />
          </div>
          <div class="col-span-4">
            <div class="text-sm text-jet pb-1">
              First Repayment Date
            </div>
            <c-text
              placeholder="Enter"
              variant="w-full -mt-1"
              disabled
              v-if="!loanAmount"
            />
            <div class="date-flex" v-else>
              <datepicker
                placeholder="--Enter--"
                input-class="date-input"
                style="width:100%; outline:none"
                :minimum-view="'month'"
                :maximum-view="'month'"
                format="MMMM yyyy"
                :disabled-dates="disabledRepaymentDates"
                @selected="formatDate($event)"
              />
            </div>
          </div>
        </div>
        <div class=" border mt-10" />
        <div class="mt-10 mb-8 ">
          <p class="text-lg font-bold">
            Repayment Schedule
          </p>
        </div>
        <div class="w-full inline-flex space-x-4">
          <div class="flex flex-wrap">
            <card
              class=" w-72 py-4 px-3 space-y-3 mb-5 ml-1 mr-3"
              v-for="(list, index) in schedule"
              :key="index"
            >
              <span
                class="block w-2 h-2 rounded-full bg-carrotOrange mb-3"
              ></span>
              <div>
                <span
                  class=" inline-block pr-6 text-romanSilver font-semibold text-sm"
                >
                  Month:
                </span>
                <span
                  class="text-darkPurple font-semibold text-base float-right"
                  >{{ list.listDate }}</span
                >
              </div>
              <div>
                <span
                  class="  inline-block pr-6 text-romanSilver font-semibold text-sm"
                >
                  Repayment amount:
                </span>
                <span
                  class=" text-darkPurple font-semibold text-base float-right"
                >
                  {{ convertToCurrency(list.amount) }}
                </span>
              </div>
              <div>
                <span
                  class="  inline-block pr-6 text-romanSilver font-semibold text-sm"
                >
                  Status:
                </span>
                <span class=" inline-block text-base float-right">
                  <span class="bage px-2 py-1 rounded text-sm font-semibold"
                    >Pending</span
                  >
                </span>
              </div>
            </card>
          </div>
        </div>
        <div class=" border mt-10" />
      </card>
      <div class=" flex my-5">
        <div class="flex">
          <Button
            class="inline-block bg-dynamicBackBtn text-white buttons rounded-md"
            :disabled="watchBtnEnabled"
            @click="applyAdvance()"
          >
            Save
          </Button>
          <Button
            @click="$router.push({ name: 'EssAdvanceLoans' })"
            class="buttons inline-block"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Datepicker from "vuejs-datepicker";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
  name: "EssNewAdvance",

  components: {
    BackButton,
    Breadcrumb,
    Card,
    CText,
    CSelect,
    Button,
    Datepicker
  },
  data() {
    return {
      payItems: [],
      payItemList: [],
      schedule: [],
      rateValue: 0,
      loanAmount: null,
      disableBtn: true,
      setLoan: false,
      error: [],
      grossPay: 0,
      isReadyToSubmit: false,
      maximumDuration: 0,
      advanceMonthlyLimit: null,
      advanceRepaymentDuration: 0,
      disableDatePick: true,
      validateAdvanceName: true,
      repaymentStartDate: "",
      salaryAdvanceRequest: {
        userId: "",
        paySettingId: null,
        type: "advance",
        interestRate: "",
        principalAmount: null,
        repaymentAmount: "",
        firstRepaymentDate: "",
        repaymentDuration: null,
        monthsAfterRepaymentStarts: null
      },
      disabledRepaymentDates: {
        to: '' // Disable all dates up to specific date
      },
      breadcrumbs: [
        {
          disabled: false,
          text: "Benefits",
          href: "benefits",
          id: "Benefits"
        },
        {
          disabled: false,
          text: "Advance And Loans",
          id: "Advance And Loans"
        },
        {
          disabled: false,
          text: "New Advance",
          id: "New Advance"
        }
      ],
      repaymentPeriodArray: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24"
      ]
    };
  },
  watch: {
    loanAmount(newValue) {
      if (newValue !== "") {
        const convertNewValueToNumber = newValue.split(",").join("");
        const salaryPercent = (this.rateValue / 100) * this.grossPay;
        if (this.advanceMonthlyLimit) {
          if (convertNewValueToNumber <= Number(this.advanceMonthlyLimit)) {
            this.error = [];
          } else {
            this.error.push(
              `Your organisation monthly limit balance is ${this.advanceMonthlyLimit}`
            );
          }
        }
        if (convertNewValueToNumber <= Math.round(salaryPercent)) {
          if (!this.error.length) {
            this.error = [];
          }
          if (this.salaryAdvanceRequest.repaymentAmount !== "") {
            this.calculatePrincipalAmount();
          }
          this.salaryAdvanceRequest.principalAmount = Number(
            convertNewValueToNumber
          );
        } else {
          this.error.push("You have exceeded amount limit");
        }
        const result = newValue
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.loanAmount = result;
      }
      else {
        this.salaryAdvanceRequest.repaymentDuration = null;
        this.salaryAdvanceRequest.firstRepaymentDate = '';
        this.salaryAdvanceRequest.repaymentAmount = '';
        this.schedule = [];
      }
    }
  },

  methods: {
    formatDate(value) {
      this.repaymentStartDate = value;
      this.schedule = [];
      const month = this.$DATEFORMAT(new Date(value), "MM");
      const year = this.$DATEFORMAT(new Date(value), "yyyy");
      const date = String(new Date().getDate()).padStart(2, "0");
      this.salaryAdvanceRequest.firstRepaymentDate = `${year}-${month}-${date}`;
      const duration = this.salaryAdvanceRequest.repaymentDuration;
      const netAmount = (this.salaryAdvanceRequest.repaymentAmount / duration);

      for (let i = 0; i < duration; i++) {
        const scheduleDate = this.$DATEFORMAT(
          new Date(year, parseInt(month, 10) + i, 0),
          "MMM yyyy"
        );

        const scheduleList = { listDate: scheduleDate, amount: netAmount };
        this.schedule.push(scheduleList);
      }
    },
    getPaySettings() {
      this.$_getEmployeeId({ id: this.$AuthUser.id }).then(res => {
        this.$_getEmployeeAdvancePaySettings({
          id: res.data.employee.id,
          type: "advance"
        }).then(({ data }) => {
          this.payItems = data.paySettings;
          this.payItemList = this.payItems.map(item => ({
            name: item.paysetting.nameOnPayslip,
            id: item.paySettingId
          }));
        });
        this.grossPay = res.data.employee.grossPay;
      });
    },
    handleSelected(optionSelected) {
      const options = this.payItems.find(
        value => value.paySettingId === optionSelected
      );
      if (options) {
        this.salaryAdvanceRequest.interestRate = options.paysetting.interest;
        this.salaryAdvanceRequest.paySettingId = options.paySettingId;
        this.rateValue = options.rateValue;
        this.advanceMonthlyLimit = options.paysetting.advanceMonthlyLimit;
        this.advanceRepaymentDuration =
          options.paysetting.advanceRepaymentDuration;
        this.maximumDuration = options.paysetting.advanceRepaymentDuration;
        this.validateAdvanceName = false;
      }
    },
    calculatePrincipalAmount() {
      this.setLoan = false;
      if (this.salaryAdvanceRequest.repaymentDuration > this.maximumDuration) {
        this.$toasted.error(
          `Repayment period should not exceed ${this.maximumDuration} months`,
          {
            duration: 5000
          }
        );
        this.salaryAdvanceRequest.repaymentAmount = "";
        this.disableDatePick = true;
        this.schedule = [];
        this.salaryAdvanceRequest.firstRepaymentDate = "";
      } else {
        this.loanAmount = this.loanAmount.split(",").join("");
        if (this.salaryAdvanceRequest.interestRate < 1) {
          this.salaryAdvanceRequest.repaymentAmount = this.loanAmount;
        } else {
          const interestAmount =
            (parseFloat(this.salaryAdvanceRequest.interestRate) / 100) *
            Number(this.loanAmount);

          const repaymentAmount = Number(this.loanAmount) + interestAmount;
          this.salaryAdvanceRequest.repaymentAmount = repaymentAmount.toFixed(
            2
          );
        }
        this.disableDatePick = false;
        if (this.salaryAdvanceRequest.firstRepaymentDate !== "") {
          this.formatDate(this.repaymentStartDate);
        }
      }
    },
    applyAdvance() {
      const payLoad = {
        userId: this.$AuthUser.id,
        type: this.salaryAdvanceRequest.type,
        paySettingId: this.salaryAdvanceRequest.paySettingId,
        repaymentDuration: Number(this.salaryAdvanceRequest.repaymentDuration),
        principalAmount: this.salaryAdvanceRequest.principalAmount,
        firstRepaymentDate: this.salaryAdvanceRequest.firstRepaymentDate
      };
      this.$_applyForAdavanceAndLoan(payLoad)
        .then(result => {
          this.$toasted.success(`${result.data.message}`, { duration: 5000 });
          this.$router.push({
            name: "EssAdvanceLoans"
          });
        })
        .catch(error => {
          this.$toasted.error(`${error}`, { duration: 5000 });
          this.cratingApplication = false;
          this.scrollTop();
        });
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.cratingApplication = false;
    }
  },

  computed: {
    watchBtnEnabled() {
     const isEmpty = Object.values(this.salaryAdvanceRequest).some(
        value => value === "" || null
      );

      return isEmpty;
    },
  },

  mounted() {
    this.getPaySettings();
    this.salaryAdvanceRequest.userId = this.$AuthUser.id;
    const currDate = new Date();
    const getDays = new Date(currDate.getFullYear(), currDate.getMonth()+1, 0).getDate();
    const getHours = 24 * getDays;
    this.disabledRepaymentDates.to = new Date(new Date().valueOf() + 1000 * 60 * 60 * getHours);
  }
};
</script>

<style scoped>
.bage {
  background-color: rgba(233, 147, 35, 0.15) !important;
  color: rgba(233, 147, 35, 1) !important;
}
</style>
